<template>
  <section>
    <h1>{{ $t(`views.${$options.name.toLowerCase()}.title`) }}</h1>

    <div>
      <h3>Vorbereitete Abrechnungen: {{formatMoney(this.totalMoneyPrepared)}}</h3>
       <b-table striped hover :items="this.preparedReport" :fields="fields">
        <template v-slot:cell(totalMoneyPrepared)="data">
            {{ formatMoney(data.item.totalMoneyPrepared)}}
        </template>
        <template v-slot:cell(totalHoursPrepared)="data">
            {{ formatNumber(data.item.totalHoursPrepared)}}
        </template>
          </b-table>
    </div>
  </section>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'
import http from '@/$plugins/http'

const REQUIRED_ACTION_ENTITY_KEYS = ['locations', 'users']

export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
      preparedReport: {},
      totalMoneyPrepared: 0,
      fields: [
        { key: 'project.name', label: 'Abrechnungstask', sortable: true },
        { key: 'totalHoursPrepared', label: 'Vorbereitet (in Stunden)', class: 'text-right', sortable: true },
        { key: 'totalMoneyPrepared', label: 'Vorbereitet (in CHF)', class: 'text-right', sortable: true }
      ]
    }
  },
  computed: {
    isRequiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/is`] }), {})
    },
    requiredActions () {
      return REQUIRED_ACTION_ENTITY_KEYS
        .reduce((entities, eKey) => Object.assign(entities, { [eKey]: this.$store.getters[`${eKey}/getRequiredActions`] }), {})
    }
  },
  methods: {
    formatNumber (number) {
      if (number === null || number === undefined) return
      return number.toLocaleString('de-CH', { minimumFractionDigits: 2 })
    },
    formatMoney (amount) {
      return parseFloat(amount).toLocaleString('de-CH', {
        style: 'currency',
        currency: 'CHF'
      })
    },
    getRequiredActivities () {
      REQUIRED_ACTION_ENTITY_KEYS
        .forEach(eKey => {
          this.$store.dispatch(`${eKey}/getRequiredActions`)
        })
    },
    async loadReports () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.business}/api/${STOREMODULE_ENDPOINT_MAP.projectreporting}/all`)
        this.preparedReport = response.data
        this.preparedReport.forEach(item => {
          this.totalMoneyPrepared += item.totalMoneyPrepared
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.getRequiredActivities()
    this.loadReports()
  }
}
</script>

<style lang="scss"></style>
